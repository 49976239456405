import React from 'react';
import './Footer.scss';

export default function Footer() {
  return (

    <footer className="pbg-footer">
      <div className="pbg-footer-container">
        <div className="pbg-footer-content">
          <span>© Copyright Plejd AB</span>
          <span className="pbg-footer-small">All rights reserved.<br/>Version 2.0.22</span>
        </div>
      </div>
    </footer>

  );
}