import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function Dialux() {

  const request = FileCardData.filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'dialux'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | DIALux</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M48 2C31 2 18 15 18 31c0 12 16 28 16 43v11c0 3.075 3.925 7 7 7h14c3.075 0 7-3.925 7-7V74c0-15 16-31 16-43C78 15 65 2 48 2zm7 86H41c-.897 0-3-2.103-3-3v-2h20v2c0 .897-2.103 3-3 3zm3-9H38v-5h20v5zm.307-9H37.693c-1.133-7.582-5.197-15.055-8.847-21.766C25.325 41.762 22 35.648 22 31 22 16.981 33.421 6 48 6s26 10.981 26 25c0 4.648-3.325 10.762-6.846 17.234-3.65 6.711-7.714 14.184-8.847 21.766zM52 17a2 2 0 0 1-2 2c-7.851 0-15 7.149-15 15a2 2 0 0 1-4 0c0-10.122 8.879-19 19-19a2 2 0 0 1 2 2z"/>}
          title={'DIALux'}
          subtitle={null}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-language-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(Dialux);