import React from 'react';
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';

function LanguageCard({item}) {

  const type = item.type;

  return(

    <div className="pbg-language-card-item">
      <motion.div
        initial={{opacity: 0.4, translateY: 20}}
        whileInView={{opacity: 1, translateY: 0}}
        viewport={{amount: 0.5, once: true}}
        transition={{
          type: "spring", stiffness: 100
        }}
      >
        <div className="pbg-language-card-item-content">
          <Link to={item.link}>
            <span className="pbg-language-card-icon">
              {type === "Product image" || type === "Compliance"
                ? <svg className="fdvbdfsdf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" height="24" width="24">{item.languageCode}</svg>
                : <span>{item.languageCode}</span>
              }
            </span>
            <span className="pbg-language-card-title">{item.language}</span>
          </Link>
        </div>
      </motion.div>
    </div>

  );
}
export default LanguageCard;